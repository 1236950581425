div.container>* {
    padding: 5px;
    white-space: normal; }
div.children {
    min-width: 10px;
    width: fit-content;
    max-width: 70vw;
    min-height: 10px;
    height: fit-content;
    max-height: 70vw;
    white-space: pre-wrap;
    font-size: 12pt;
    word-break: break-word; }
div.button-panel {
    width: 100%;
    margin-right: 20px;
    padding-bottom: 30px;

    button {
        min-width: 110px; } }
.modal-title {
    max-width: 100%;
    word-break: break-word; }
.left {
    float: left; }
.right {
    float: right; }
.delete-link {
    padding-left: 20px;
    color: gray;
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: red; } }
div.modal-window {
    div.item {
        padding-top: 12px; }
    div#toolbar {
        margin-top: 10px;
        border-top: dotted 1px #aaaaaa;
        padding-top: 10px;
        padding-bottom: 10px; }

    table.editform {
        width: 100%;
        display: block;
        max-height: 55vh;
        border-collapse: collapse;
        overflow-x: auto;
        padding-bottom: 15px;

        thead {

            th {
                text-align: left;
                font-size: 10pt;
                font-weight: normal;
                padding-left: 5px;
                padding-bottom: 15px;
                border-right-style: dotted;
                border-right-width: 1px;
                border-right-color: #cccccc;
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                background-color: #fff;
                box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
                z-index: 1;

                &.tools-column {
                    width: 30px;
                    border-right-style: none; }
                &.props-column {
                    z-index: 4; }
                &:first-child {
                    position: -webkit-sticky;
                    position: sticky;
                    left: 0;
                    z-index: 3; } } }

        tr {
            td {
                padding-top: 15px;
                vertical-align: top;
                border-right-style: dotted;
                border-right-width: 1px;
                border-right-color: #cccccc;
                padding-left: 4px;
                padding-right: 4px;
                label {
                    width: 90%; }

                button {
                    min-width: 90px; }
                input {
                    min-width: 50px; }

                &.tools {
                    padding-left: 10px;
                    border-right-style: none;
                    button {
                        min-width: 20px;
                        width: 20px; } }
                &.propscol {
                    margin: 0px;
                    padding: 0px;
                    padding-top: 10px;
                    background-color: #fff; }
                &:first-child {
                    position: -webkit-sticky;
                    position: sticky;
                    left: 0;
                    z-index: 2;
                    background: #fff; } } } }
    table.props {
        tr {
            td {
                vertical-align: top;
                border-right-style: none;
                margin: 0px;
                padding: 0px;
                input {
                    min-width: 50px; } }
            td.content {
                input {
                    min-width: 250px; } } } } }



