$gap: 10px;

div.w-file-upload-view {
    display: grid;
    grid-column-gap: $gap;
    margin-left: 0;
    grid-template-columns: repeat(1, 100%);
    line-height: 20px;
    font-size: 14px;
    grid-column: 1 / 3;
    label.label-file-upload {
        width: 100%;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        border: 1px dashed rgba(0, 0, 0, 0.37);
        box-sizing: border-box;
        border-radius: 2px;
        outline: none;
        cursor: default;
        padding: 5px 7px;
        transition: box-shadow 0.3s ease;
        div.content-file-upload {
            display: flex;
            width: 100%;
            align-items: center;
            height: 100%;
            -webkit-box-align: center;
            span.link-file-upload {
                outline: none;
                text-decoration: none;
                color: #1874cf; }
            div.filename-upload-1 {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -webkit-justify-content: space-between;
                justify-content: space-between;
                -webkit-flex: 1 1 auto;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto; }
            div.filename-upload-2 {
                width: 100%; }
            div.filename-upload-3 {
                width: 100%;
                div.file-file-upload {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    position: relative;
                    -webkit-box-align: center;
                    cursor: default;
                    span.filename-file-upload {
                        flex: 1 1 100%;
                        overflow: hidden; }
                    div.fileicon-file-upload {
                        width: 16px;
                        marging-left: 23px;
                        flex: 1 0 auto;
                        font-size: 14px;
                        text-align: right;
                        outline: none;
                        span {
                            color: #808080;
                            svg {
                                margin-bottom: -0.1875em;
                                height: 1.1428571428571428em;
                                width: 1.1428571428571428em; } } }

                    div.fileicon-file-upload[data-disabled="true"] {
                        cursor: default; }
                    div.fileicon-file-upload[data-disabled="false"] {
                        cursor: pointer; } } } } } }

div.w-file-upload-edit {
    display: grid;
    grid-column-gap: $gap;
    margin-left: 0;
    grid-template-columns: repeat(1, 100%);
    grid-column: 1 / 3; }
