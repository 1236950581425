.role-list {
    height: 256px;
    background-color: #f6f6f6;
    padding: 12px; }

.role {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.role:hover {
    background-color: #eee;
    border-radius: 8px; }


