@import "../../branding/Branding";

$minWidth: 1300px;
$fontSize: 1.2vw;

div.logo-type {
    padding: 5px 0 0 9px;
    a span {
        &:nth-child(1) {
            width: 5vw; }
        &:nth-child(2) {
            width: 15vw; } } }

div.logo-type-en {
    padding: 5px 0 0 9px;
    a span {
        &:nth-child(1) {
            width: 4.5vw; }
        &:nth-child(2) {
            width: 9vw; } } }

div.logo-appname {
    padding: 10px 0 10px 17px;
    white-space: nowrap;
    font-size: 16px;
    color: $brandColorSelected; }

.custom-header {
    display: flex;
    text-decoration: none;
    color: #222;
    p {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: 1;
        color: #222222;
        white-space: normal;

        overflow: hidden;
        overflow-wrap: break-word;
        overflow-wrap: anywhere;
        word-wrap: break-word;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        max-height: 100%;
        text-overflow: ellipsis;
        padding-bottom: 4px; } }

div.custom-logo-icon {
    padding: 8px 0 0 12px;
    height: 50px; }

div.custom-logo-icon-show-full-items {
    padding: 0px 0 0 0px;
    height: 50px; }

div.logo-icon {
    padding: 20px 0 0 13px;
    height: 50px; }

@media screen and (min-width: $minWidth) {
    div.logo-type {
        a span {
            &:nth-child(1) {
                width: 50pt; }
            &:nth-child(2) {
                width: 149pt; } } }

    div.logo-type-en {
        a span {
            &:nth-child(1) {
                width: 44.8pt; }
            &:nth-child(2) {
                width: 88pt; } } }

    div.logo-appname {
        padding: 10px 0 10px 17px;
        white-space: nowrap;
        font-size: 16px;
        color: $brandColorSelected; } }
