@import "../../branding/Branding";

span.w-text-edit {
    font-size: $defaultFontSize;
    width: 100%; }

span.w-text-view {
    font-size: $defaultFontSize; }

.w-text-view-overflow {
    overflow: hidden;
    text-overflow: ellipsis; }
