.search-input {
    float: right;
    padding-right: 32px; }

.group-tap-container {
    padding-top: 16px; }

.user-list-container {
    padding-top: 16px;
    display: flex;
    flex-direction: column; }

.groups-header {
    display: grid;
    grid-template-columns: 4fr 4fr 4fr 1fr;
    border-bottom: #eee solid 2px;
    font-size: 0.8fr;
    color: rgb(128, 128, 128); }

.groups-header div:hover {
    cursor: pointer;
    color: #28385b; }

.users-header {
    display: grid;
    grid-template-columns: 2fr 1.1fr 1fr 1fr 2fr 1fr;
    border-bottom: #eee solid 2px;
    font-size: 0.8fr;
    color: rgb(128, 128, 128); }

.users-header div:hover {
    cursor: pointer;
    color: #28385b; }

.users-header .role-header:hover {
    color: rgba(40, 56, 91, 0.29);
    cursor: default; }

.slightly-gray {
    color: rgb(128, 128, 128); }

.user-row {
    padding: 12px 0 12px 0;
    border-bottom: #eee solid 2px;
    display: grid;
    grid-template-columns: 2fr 1.1fr 1fr 1fr 2fr 1fr; }

.group-row {
    padding: 12px 0 12px 0;
    border-bottom: #eee solid 2px;
    display: grid;
    grid-template-columns: 4fr 4fr 4fr 1fr; }

.group-row:hover {
    cursor: pointer;
    background-color: #eee; }

.user-row:hover {
    cursor: pointer;
    background-color: #eee; }

.delete {
    width: 18px;
    height: 18px;
    align-content: center;
    color: white;
    background-color: #dd473b;
    border-radius: 50%;
    padding: 2px 1px 2px 2px; }

.delete:hover {
    color: #dd473b;
    transform: scale(1.4); }



.delete:hover {
    background-color: rgba(241, 240, 240, 0.18); }


