@import "../../../../branding/Branding";

$indent: 5px;
$innerIndent: 2px;
$lineDelimiter: 1px dashed grey;
$accountWidth: 12.0em;

.typical-operation-selected {
    background-color: $brandColorSelected;
    color: white; }

div.algorithm-panel {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: grid;
    height: 100%;
    grid-template-columns: auto 300px;
    grid-template-rows: 66px 200px 50px 55px 0 100px  auto;
    grid-template-areas: "actions actions" "tree tree" "details-tab details-tab" "title title" "script-area script-area" "header props" "table props";
    box-sizing: border-box; }

div.typical-operation-title {
    font-size: 1.25rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    padding-top: 5px; }

div.typical-operations-actions {
    grid-area: actions; }

div.algorithmDetails {
    margin-left: 10px;
    grid-area: details-tab;
    width: 99%;
    margin-right: 2px; }

div.scriptPanel {
    grid-area: script-area;
    overflow-y: auto;
    width: 100%;
    height: 32.2rem; }

div.wscript-editor {
    width: 100%;
    height: 100%; }

div.typical-operations-tree {
    grid-area: tree;
    overflow-x: auto;
    overflow-y: auto;
    padding-left: 10px;
    border-bottom: solid 1px grey;
    width: available;
    height: available;
    ul {
        width: 100rem; } }

div.typical-operations-tree-dialog {
    grid-area: tree;
    overflow-x: auto;
    overflow-y: auto;
    padding-left: 10px;
    width: fit-content;
    min-width: 300px;
    min-height: 220px;
    ul {
        max-width: 300px; } }

div.typical-operation-details-group {
    border: solid 1px grey;
    display: none; }

div.typical-operation-details-template-title {
    grid-area: title;
    background-color: $brandColorSelected;
    color: white;
    padding-left: $indent; }

div.algorithm-details-template-header {
    grid-area: header;
    display: grid;
    grid-template-columns: 7em 11em 9em auto;
    column-gap: 16px;
    grid-auto-rows: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    div.item {
        padding-left: $indent;
        // padding-right: $indent
        >span:nth-child(1)::after {
            content: ":"; }
        >span:nth-child(2) {
            cursor: pointer;
            padding-left: $innerIndent;
            padding-right: $innerIndent; }
        span {
            margin-bottom: 0; } }
    div#plan {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 1;
        span#plan\.label {
            display: inline; }
        span#plan {
            display: inline; } }
    div#OPERATION_DATE {
        display: grid;
        grid-template-columns: 2em auto;
        column-gap: 4px;
        align-items: center;
        grid-column-start: 1;
        grid-row-start: 2;
        span#OPERATION_DATE\.label {}
        span#OPERATION_DATE {
            display: inline-block;
            min-height: 1.4em;
            max-height: 2.8em;
            min-width: 5.5em;
            border: solid 1px grey; } }
    div#OPERATION_NUMBER {
        display: grid;
        grid-template-columns: 3em auto;
        column-gap: 4px;
        align-items: center;
        grid-column-start: 2;
        grid-row-start: 2;
        span#OPERATION_NUMBER\.label {}
        span#OPERATION_NUMBER {
            border: solid 1px grey;
            display: inline-block;
            height: 1.4em; } }
    div#OPERATION_SUM {
        display: grid;
        grid-template-columns: 3em auto;
        column-gap: 0px;
        align-items: center;
        grid-column-start: 3;
        grid-row-start: 2;
        span#OPERATION_SUM\.label {}
        span#OPERATION_SUM {
            display: inline-block;
            height: 1.4em;
            width: fit-content;
            min-width: 105px;
            max-width: 105px;
            border: solid gray 1px;
            padding: 0px;
            overflow: hidden; } }
    div#OPERATION_DESCRIPTION {
        display: grid;
        grid-template-columns: 5em auto;
        column-gap: 6px;
        align-items: center;
        grid-column-start: 4;
        grid-row-start: 2;
        span#OPERATION_DESCRIPTION\.label {}
        span#OPERATION_DESCRIPTION {
            display: inline-block;
            min-height: 1.4em;
            max-height: 4.2em;
            min-width: 5em;
            border: solid gray 1px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis; } }
    div#comment {
        display: grid;
        grid-template-columns: 5.5em auto;
        column-gap: 4px;
        align-items: center;
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 3;
        span#comment\.label {}
        span#comment {
            display: inline-block;
            height: 1.4em;
            border: solid gray 1px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis; } } }

div.algorithm-details-template-table {
    grid-area: table;
    table, td, th {
        border: solid gray 1px; }

    table {
        border-collapse: collapse;
        width: 100%;

        th, td {
            padding-left: $innerIndent;
            padding-right: $innerIndent; }
        th.DEBIT_ACCOUNT {
            width: $accountWidth; }
        th.CREDIT_ACCOUNT {
            width: $accountWidth; }
        td {
            vertical-align: top;
            span {
                display: block;
                width: 100%; } }

        td.NUMBER {
            span { // номер
                display: inline-block;
                height: 1.4em;
                width: 100%;
                text-align: right;
                border-bottom: $lineDelimiter;
                cursor: pointer; } }
        td.ROW_SOURCE {
            span { // источник
                display: inline-block;
                min-height: 1.4em;
                height: 1.4em;
                text-align: left;
                border-bottom: $lineDelimiter;
                cursor: pointer; } }
        td.DEBIT_ACCOUNT {
            width: $accountWidth;
            span { // дебет
                display: inline-block;
                height: 1.4em;
                text-align: left;
                border-bottom: $lineDelimiter;
                cursor: pointer; } }
        td.DEBIT_ANALYTIC span { // дебет аналитика
            display: inline-block;
            min-height: 1.4em;
            text-align: left;
            border-bottom: $lineDelimiter;
            cursor: pointer; }
        td.CREDIT_ACCOUNT {
            width: $accountWidth;
            span { // кредит
                display: inline-block;
                height: 1.4em;
                text-align: left;
                border-bottom: $lineDelimiter; } }
        td.CREDIT_ANALYTIC {
            span { // кредит аналитика
                display: inline-block;
                min-height: 1.4em;
                text-align: left;
                border-bottom: $lineDelimiter;
                cursor: pointer; } }
        td.CURRENCY {
            align-items: center;
            span#CURRENCY { // валюта, курс
                display: inline-block;
                height: 1.4em;
                text-align: left;
                border-bottom: $lineDelimiter;
                cursor: pointer; }
            span#EXCHANGE_RATE { // валюта, курс
                display: inline-block;
                height: 1.4em;
                text-align: center;
                border-bottom: $lineDelimiter;
                cursor: pointer; } }
        td.COUNT { // количество, содержание проводки, основание
            span#COUNT { // количество
                display: inline-block;
                height: 1.4em;
                text-align: center;
                border-bottom: $lineDelimiter;
                cursor: pointer; }
            span#POSTING_DESCRIPTION { // содержание проводки
                display: inline-block;
                min-height: 1.4em;
                text-align: left;
                border-bottom: $lineDelimiter;
                cursor: pointer; }
            span#SOURCE { // основание
                display: inline-block;
                min-height: 1.4em;
                text-align: left;
                border-bottom: $lineDelimiter;
                cursor: pointer; } }
        td.CURRENCY_SUM { // валютная сумма
            span#CURRENCY_SUM {
                display: inline-block;
                height: 1.4em;
                text-align: center;
                border-bottom: $lineDelimiter;
                cursor: pointer; } }
        td.SUM {
            span#SUM { // сумма
                display: inline-block;
                height: 1.4em;
                text-align: center;
                border-bottom: $lineDelimiter;
                cursor: pointer; }
            span#JOURNAL_NUM { // номер журнала
                display: inline-block;
                height: 1.4em;
                text-align: left;
                border-bottom: $lineDelimiter;
                cursor: pointer; } } } }

div.algorithm-details-template-props {
    grid-area: props;
    border: solid 10px white;
    width: 93%;
    height: max-content;
    text-align: left;
    div.typical-operation-details-template-props-panel {
        border: solid 1px grey;
        padding-bottom: 5px;
        margin-top: 9px;
        div.item {
            padding-left: $indent;
            margin-top: 5px;
            margin-bottom: 8px;
            >span:nth-child(1) {
                font-weight: bold; }
            >span:nth-child(2) {
                width: 97%;
                display: block;
                overflow-wrap: break-word;
                min-height: 1.2em;
                min-width: 3em; } } } }
