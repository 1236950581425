div.tree-view-panel-container {
    overflow: hidden;
    position: relative;
    width: "100%";
    height: "100%";
    div.lazy-table-view-header {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        gap: 24px;
        margin-top: 8px;

        section.lazy-table-view-section-title {
            span.lazy-table-view-header-title {
                font-family: 'Lab Grotesque K';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 40px; } }
        section.lazy-table-view-section-controls {
            display: flex;
            flex-wrap: wrap;
            align-content: baseline;
            gap: 8px 8px;
            margin-right: 24px;
            div.lazy-table-view-header-settings {
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                border: solid 1px rgba(0, 0, 0, 0.16);
                width: 32px;
                &:hover {
                    background-color: #f2f2f2;
                    background-image: none;
                    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.16); }

                button.lazy-table-view-header-settings-account-code-item {
                    padding 5px 8px 3px 36px {} } } } }
    div.tree-view-panel-control {
        width: 100vw;
        div.tree-node-container {
            display: flex;
            div.tree-node-label-icon {
                padding-left: 4px;
                padding-right: 8px; }
            div.tree-node-content-line-1 {
                span.tree-node-content-line-column {
                    display: inline-block;
                    height: 20px;
                    width: 100%;
                    font-family: "Lab Grotesque K";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #222222; } }
            div.tree-node-content-line-n {
                span.tree-node-content-line-column {
                    display: inline-block;
                    height: 16px;
                    width: 100%;
                    font-family: "Lab Grotesque K";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: #858585; } } }
        li[aria-expanded="true"] {
            ul[role="group"] {
                border-left: 1px solid rgba(0, 0, 0, 0.04);
                margin-left: 8px;
                padding-left: 8px; } } } }




