div#button-container {
    float: left;
    margin-left: 5px;
    margin-top: 20px;
    padding-bottom: 20px;
    span {
        padding-right: 5px; } }

div.postings {
    margin: 20px;
    td {
        white-space: normal; } }


