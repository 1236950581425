$numberWidth: 70px;
$dateWidth: 70px;

span.w-combobox-edit {
    width: 100%;
    min-width: 200px;
    max-width: 100%; }
div.document-combobox-items-container {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    div.empty-value {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
    div.document-number {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
    div.document-date {
        width: $dateWidth;
        max-width: $dateWidth;
        min-width: $dateWidth;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
    div.items-container-edit {
        padding-left: 5px;
        opacity: 0.5;
        margin-right: 0;
        margin-left: auto;
        boxSizing: border-box;
        &:hover {
            opacity: 1.0; } }
    .add-button-item {
        max-width: 506px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; } }

