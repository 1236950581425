div.user-menu {
    padding-bottom: 10px;
    div.user-menu-container {
        height: 100%;
        width: 100%;
        display: inline-block;
        flex-direction: column;
        justify-content: end;
        padding-bottom: 10px;
        div.user-item {
            padding: 5px 8px 5px 8px;
            background: #F6F6F6;
            border-radius: 8px;
            color: #222222;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-left: 8px;
            margin-right: 8px;
            span.user-item-title {
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%; } }
        div.user-item:hover {
            background: #ebebeb; } } }
.user-link:hover {
    text-decoration: none; }

.usermenu-item {
    width: 10vw;
    font-size: 14px;
    fontFamily: "Lab Grotesque K";
    fontWeight: 400;
    padding: 5px 10px 5px 10px;
    opacity: .9999999;
    span {
        position: relative;
        z-index: -1; } }
.usermenu-item:hover {
    border-radius: 8px;
    background: #F6F6F6 !important;
    color: #222222 !important; }
.usermenu-item-selected {
    border-radius: 8px;
    background: #F2F2F2 !important;
    color: #222222 !important;
    fontWeight: 400;
    fontFamily: "Lab Grotesque K";
    border-radius: 8px;
    font-size: 14px; }




