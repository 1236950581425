div.menu-container {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    width: 100%; }

div.main-menu {
    margin: 10px 11px 0px 7px;
    background: #F6F6F6;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #222222;
    width: 100%; }

.mainmenu-item {
    font-size: 16px;
    fontFamily: "Lab Grotesque K";
    fontWeight: 400;
    border-radius: 8px; }
.mainmenu-item:hover {
    background: #ebebeb; }
.mainmenu-item-selected {
    font-size: 16px;
    fontWeight: 400;
    fontFamily: "Lab Grotesque K";
    border-radius: 8px;
    background: #fff; }

div.user-menu {
  position: fixed;
  height: 110px;
  background: #F6F6F6;
  bottom: 0;
  left: 0; }

div.sub-menu {
    padding: 10px 10px 0px 10px;
    box-shadow: 1px 0px 0px #EBEBEB;
    top: 0px;
    background: #fff;
    position: fixed;
    height: 100%;
    bottom: 0;
    transition: all .2s ease-in-out; }

.tree-submenu {
    background: #fff;
    font-weight: 400;
    line-height: 22px;
    color: #222222;
    letter-spacing: 0em; }

.submenu-item {
    font-family: "Lab Grotesque K";
    border-radius: 8px;
    margin-left: 16px; }
.submenu-item:hover {
    background: #EBEBEB !important; }
.MuiTreeItem-label:hover {
    background: #EBEBEB !important; }
.submenu-item-selected {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.04); }

.MuiTypography-body1 {
    padding: 3px;
    font-family: "Lab Grotesque K";
    font-weight: 400; }

.divider {
    position: absolute;
    top: 0px;
    height: 100%;
    margin: 1rem;
    border: 1px solid #808080; }
