@import "../../../branding/Branding.sass";

$gap: 10px;

div#filter-body-container {
    padding: 20px 0px;

    p.title {
        font-size: 10pt;
        //text-transform: capitalize
        margin-top: 0px;
        margin-bottom: 5px; }

    div.filter-form {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-column-gap: $gap;
        grid-row-gap: $gap;
        padding-bottom: 10px;
        border-bottom-style: dotted;
        border-bottom-width: 1px;
        border-bottom-color: #aaaaaa;
        margin-bottom: 10px;

        div.item {
            display: flex;
            justify-content: left;
            align-items: center;

            span[data-value='0'] {
                button {
                    color: gray; } }

            span.w-label-edit {
                flex: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 30px; }

            span.w-text-edit {
                flex: 1;
                label {
                    width: inherit !important;
                    input {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis; } } }

            span.w-money-edit {
                flex: 1;
                label {
                    width: inherit !important; } }

            span.w-selector-edit {
                flex: 1;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                >span {
                    width: 100%;
                    >span {
                        width: 100% !important;
                        max-width: 100% !important;
                        button {
                            width: inherit; } } } }

            >span.w-date-edit {
                flex: 1;
                label {
                    width: Min(110px,100%) !important; } }

            div.w-date-interval {
                display: flex;
                flex: 1;
                span.w-date-edit {
                    flex: 1;
                    label {
                        width: 100% !important; } } } } }

    div.filter-acoounting-organizatons {
        >span.w-text-view {
            font-size: 10pt; }
        table {
            width: 100%;
            thead {
                text-align: left;
                th.checked {
                    width: 50px; }
                th.title {
                    span.w-label-view {} } }
            tbody {
                td.checked {
                    width: 50px; } } } } }

div#filter-footer-container {
    display: grid;
    grid-template-columns: 110px 110px auto 150px;
    grid-template-rows: 32px;
    grid-template-areas: 'apply close . cancel';
    grid-column-gap: 20px;
    box-sizing: border-box;

    span.button_apply {
        grid-area: apply;
        color: white;
        background-color: $brandColorSelected; }

    span.button_close {
        grid-area: close; }

    span.button_cancel {
        grid-area: cancel; } }
