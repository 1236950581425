div.lazy-table-view-header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 24px;
    margin-top: 8px;

    section.lazy-table-view-section-title {
        span.lazy-table-view-header-title {
            font-family: 'Lab Grotesque K';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px; } }
    section.lazy-table-view-section-controls {
        display: flex;
        flex-wrap: wrap;
        align-content: baseline;
        gap: 8px 8px;
        margin-right: 24px;
        div.lazy-table-view-header-settings {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            border: solid 1px rgba(0, 0, 0, 0.16);
            width: 32px;
            &:hover {
                background-color: #f2f2f2;
                background-image: none;
                box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.16); }

            button.lazy-table-view-header-settings-account-code-item {
                padding 5px 8px 3px 36px {} } } } }
