@import "../../../branding/Branding";

$fontsize: 10pt;
$gap: 10px;

div#sidepage-body-container {
    div#toolbar {
        float: left;
        padding-bottom: 7px; }

    div#add-row-button {
        padding-bottom: 7px;
        padding-top: 10px; }

    table.periodical-history {
        width: 100%;

        tr {
            font-size: $fontsize;
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-bottom-color: white;

            &:hover {
                color: $brandColor;
                cursor: pointer;
                border-bottom-style: solid;
                border-bottom-width: 1px;
                border-bottom-color: $brandColor; } }

        td {
            font-size: $fontsize;
            &.date {
                width: 200px; } } }

    table.editform {
        width: 100%;
        display: block;
        max-height: 55vh;
        border-collapse: collapse;
        overflow-x: auto;
        padding-bottom: 15px;

        thead {

            th {
                text-align: left;
                font-size: 10pt;
                font-weight: normal;
                padding-left: 5px;
                padding-bottom: 15px;
                border-right-style: dotted;
                border-right-width: 1px;
                border-right-color: #cccccc;
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                background-color: #fff;
                box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
                z-index: 1;

                &.tools-column {
                    width: 30px;
                    border-right-style: none; }
                &.props-column {
                    z-index: 4; }
                &:first-child {
                    position: -webkit-sticky;
                    position: sticky;
                    left: 0;
                    z-index: 3; } } }

        tr {
            td {
                padding-top: 15px;
                vertical-align: top;
                border-right-style: dotted;
                border-right-width: 1px;
                border-right-color: #cccccc;
                padding-left: 4px;
                padding-right: 4px;
                label {
                    width: 90%; }

                button {
                    min-width: 90px; }
                input {
                    min-width: 50px; }

                &.tools {
                    padding-left: 10px;
                    border-right-style: none;
                    button {
                        min-width: 20px;
                        width: 20px; } }
                &.propscol {
                    margin: 0px;
                    padding: 0px;
                    padding-top: 10px;
                    background-color: #fff; }
                &:first-child {
                    position: -webkit-sticky;
                    position: sticky;
                    left: 0;
                    z-index: 2;
                    background: #fff; } } } }
    table.props {
        tr {
            td {
                vertical-align: top;
                border-right-style: none;
                margin: 0px;
                padding: 0px;
                input {
                    min-width: 50px; } }
            td.content {
                input {
                    min-width: 250px; } } } }

    div.editform {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-column-gap: $gap;
        grid-row-gap: $gap;
        padding-bottom: 10px;
        border-bottom-style: dotted;
        border-bottom-width: 1px;
        border-bottom-color: #aaaaaa;
        margin-bottom: 10px;

        div.item {
            display: grid;
            grid-template-columns: repeat(2, 50%);
            grid-column-gap: $gap;
            margin-left: $gap;

            span.w-label-edit {
                overflow: hidden;
                white-space: pre-line;
                align-items: stretch;
                text-overflow: ellipsis;
                line-height: 30px; }

            span.w-text-edit {
                label {
                    width: inherit !important;
                    input {
                        text-overflow: ellipsis; } } }

            span.w-money-edit {
                label {
                    width: inherit !important; } }

            span.w-selector-edit {
                width: 100%;
                >span {
                    width: 100%;
                    >span {
                        width: 100% !important;
                        max-width: 100% !important;
                        button {
                            width: inherit; } } } }

            span.w-date-edit {
                label {
                    width: Min(110px, 100%) !important; } }

            div.button {
                width: auto; } } }

    table.postings {
        td {
            font-size: 8pt;
            white-space: nowrap; } }

    div.editview-container {
        padding-top: 10px; }

    span[data-editable="false"] {
        span[data-tid="InputLikeText__input"] {
            color: #222222;
            -webkit-text-fill-color: #222222; }
        input {
            color: #222222;
            -webkit-text-fill-color: #222222; }
        button[disabled] {
            color: #222222;
            -webkit-text-fill-color: #222222; } } }

div#posting-tab {
    width: 100%;
    div.posting-date-label {
        margin-bottom: 20px;
        white-space: nowrap; }
    table#editform-postings {
        min-width: 99%;
        max-width: 99%;
        font-weight: normal;
        text-align: left;
        font-size: $fontsize;
        border-collapse: collapse;

        th {
            border-spacing: 0;
            border-collapse: collapse;
            border: solid 1px #cccccc;
            text-align: left;
            font-size: $fontsize;
            font-weight: bold;
            &.debit-column {
                width: 15%; }
            &.credit-column {
                width: 15%; }
            &.account-props-column {
                width: 10%; }
            &.comment-column {
                width: 20%; }
            &.journal-total-column {
                width: 10%;
                text-align: right; }
            &.journal-date-column {
                white-space: nowrap;
                width: 5%; }
            &.journal-document-column {
                width: 10%; }
            &.journal-operation-content-column {
                width: 25%; } }
        td {
            vertical-align: top;
            padding-left: 2px;
            border-style: solid;
            border-width: 1px;
            border-color: #cccccc;

            &.analytics-table-cell {
                width: 15%;
                height: 100%;
                text-align: left; }
            &.sum-cell {
                width: 10%;
                height: 100%;
                text-align: right; }

            table.posting-analytics-table {
                table-layout: fixed;
                width: 100%;
                overflow-wrap: break-word;

                td {
                    text-align: left;
                    padding-left: 15px;
                    border-top-style: none;
                    border-left-style: none;
                    border-right-style: none;
                    border-bottom-style: dotted; }
                tr:last-child td {
                    border-bottom-style: none; } } }
        td,th {
            table.journal-operation-content {
                width: 100%;
                border-spacing: 0;
                border-collapse: collapse;
                border-style: none;
                tr:first-child td {
                    border-top-style: none; }
                tr:last-child td {
                    border-bottom-style: none; }
                tr:last-child {
                    height: 100%; }
                td {
                    width: 33%;
                    white-space: nowrap;
                    border-top-style: solid;
                    border-left-style: solid;
                    border-right-style: solid;
                    border-bottom-style: solid;
                    &:last-child {
                       border-right-style: none; }
                    &:first-child {
                       border-left-style: none; }
                    &.journal-operation-content-total-header {
                        width: 33%;
                        text-align: right;
                        white-space: nowrap; }
                    &.journal-operation-content-total-cell {
                        text-align: right;
                        width: 33%; }
                    &.journal-operation-comment-cell {
                        background-color: #fff; } } } } } }

#posting-tab:after {
    content: "";
    display: block;
    height: 20px;
    width: 100%; }
