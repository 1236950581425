@import "../../../branding/Branding.sass";

div#operation-dialog-body {
  max-width: 60vw;
  min-width: 20vw;
  max-height: 30vw;
  min-height: 10vw; }

span#operation-status-icon {
  float: right; }

tr.MuiTableRow-root {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: white;
  &:hover {
    background-color: #eeeeee;
    color: $brandColor;
    cursor: pointer;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $brandColor; } }

