@import "../../branding/Branding";

span.w-label-view {
    font-size: inherit; }

span.w-label-edit {
    font-size: inherit;
    width: 100%;
    height: 100%;
    min-width: 30%;
    display: inline-flex;
    align-items: center;
    margin-bottom: 4px;
    white-space: pre-line;

    &.required {
        font-weight: bold; } }
