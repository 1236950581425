@import "../../branding/Branding";

div.w-tooltip {
    max-width: 350px;
    fontFamily: 'Segoe UI';
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word; }
