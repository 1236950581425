@import "../../branding/Branding";

div.auth-panel-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; }

div.auth-panel-items {
    box-shadow: 4px 0px 40px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 45px; }

div.auth-buttons {
    margin-top: 25px; }

div.auth-copyright {
    font-size: 12px;
    color: #858585;
    margin-top: 30px;
    text-align: center; }

div.auth-panel-password {
    >label {
        width: 100%;
        max-width: 250px; }
    >span {
        width: 100%;
        max-width: 250px; } }

div.field-application {
    >span {
        width: 100%;
        max-width: 250px; } }

div.field-login {
    >label {
        width: 100%;
        max-width: 250px; }
    >span {
        width: 100%;
        max-width: 250px; } }

div.field-accounting-organization {
    >span>span {
        width: 100%;
        max-width: 250px; } }

div.logo {
    display: block;
    margin: 0 auto;
    text-align: center; }

div.logo_1 {
    display: block;
    margin: 0 auto;
    text-align: center; }

div.logo_2 {
    display: block;
    margin: 0 auto;
    text-align: center; }

p.title {
    margin-bottom: 10px;
    color: #858585; }

p.login {
    color: #858585;
    text-align: center; }

.circle {
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  width: 3em;
  height: 3em;
  border: 2px solid black;
  border-radius: 50%;
  line-height: 3em;
  text-align: center; }

p.user-data {
    margin-top: 10px;
    margin-bottom: 40px;
    font-weight: bold;
    text-align: center; }

div.status-message {
    font-size: 11pt;
    color: $brandColorSelected;
    margin-top: 10px; }

div.error-message {
    font-size: 11pt;
    color: $brandColorError;
    margin-top: 10px;
    max-width: 250px;
    width: 100%; }
