div#sidepage-body-container {
    padding: 20px 0px;

    p.title {
        font-size: 10pt;
        //text-transform: capitalize
        margin-top: 0px;
        margin-bottom: 5px; } }

div#sidepage-footer-container {
    min-width: 25vw;
    span {
        padding-left: 5px; } }

div#sidepage-footer-closebutton-container {
    float: right; }
