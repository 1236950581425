div.accounting-entries-panel {
    height: calc(100% - 0px);
    table.accounting-entries-panel-header {
        width: 100%;
        table-layout: fixed;
        overflow-wrap: break-word;
        border: none;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px;
        border-collapse: collapse;
        td.accounting-entries-panel-header-column {
            margin: 0px;
            padding: 12px 8px 12px 8px;
            border-bottom: solid 1px #cccccc;
            border-right: solid 1px #cccccc; }
        td.accounting-entries-panel-props-header {
            border-bottom: none; } } }
table.accounting-entries-panel-content {
    width: 100%;
    table-layout: fixed;
    white-space: normal;
    word-break: break-word;
    margin: 0px;
    border-collapse: collapse;
    td.accounting-entries-panel-cell {
        border-left: solid 1px #cccccc;
        border-top: solid 1px #cccccc;
        padding: 12px 8px 12px 8px;
        margin: 0px; }
    td.accounting-entries-panel-count {
        text-align: right; }
    td.accounting-entries-panel-currency-amount {
        text-align: right; }
    td.accounting-entries-panel-value {
        text-align: right;
        border-right: solid 1px #cccccc; }
    td.accounting-entries-panel-comment {
        text-align: left;
        border-right: solid 1px #cccccc; }
    td.accounting-entries-panel-origin {
        text-align: left; }
    td.accounting-entries-panel-journal-number {
        text-align: left;
        border-right: solid 1px #cccccc; }
    td.accounting-entries-panel-props {
        text-align: left;
        border-right: solid 1px #cccccc; } }


