@import "../../../branding/Branding";

div.container {
    height: 100%;
    overflow-y: auto; }

div.wrapper {
    display: grid;
    grid-gap: 20px;
    height: 100%; }

table.tableComponent {
        width: 100%;
        display: block;
        max-height: 55vh;
        border-collapse: collapse;
        overflow-x: auto;
        padding-bottom: 15px;

        thead {

            th {
                text-align: left;
                font-size: 10pt;
                font-weight: normal;
                padding-left: 5px;
                padding-bottom: 15px;
                border-right-style: dotted;
                border-right-width: 1px;
                border-right-color: #cccccc;
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                background-color: #fff;
                box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
                z-index: 1;

                &.tools-column {
                    width: 30px;
                    border-right-style: none; }
                &.props-column {
                    z-index: 4; }
                &:first-child {
                    position: -webkit-sticky;
                    position: sticky;
                    left: 0;
                    z-index: 3; } } }

        tr {
            td {
                padding-top: 15px;
                vertical-align: top;
                border-right-style: dotted;
                border-right-width: 1px;
                border-right-color: #cccccc;
                padding-left: 4px;
                padding-right: 4px;
                label {
                    width: 90%; }

                button {
                    min-width: 90px; }
                input {
                    min-width: 50px; }

                &.tools {
                    padding-left: 10px;
                    border-right-style: none;
                    button {
                        min-width: 20px;
                        width: 20px; } }
                &.propscol {
                    margin: 0px;
                    padding: 0px;
                    padding-top: 10px;
                    background-color: #fff; }
                &:first-child {
                    position: -webkit-sticky;
                    position: sticky;
                    left: 0;
                    z-index: 2;
                    background: #fff; } } } }

header.visual-form-header {
    text-align: center;
    font-size: 24px; }
