div.lazy-table-toolbar:focus-within {
    border-bottom: 1px solid #1C7EDF;
    font-family: 'Lab Grotesque K';
    font-style: normal;
    font-weight: 400; }

div.lazy-table-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 8px;
    margin-top: 8px;
    margin-left: 0px;
    margin-right: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    label.lazy-table-toolbar-search-input {
        border-color: #fff;
        box-shadow: none;
        input {
            font: inherit;
            color: currentColor;
            width: 100%;
            border: 0;
            height: 1.1876em;
            margin: 0;
            display: block;
            padding: 6px 0 7px;
            min-width: 0;
            background: none;
            box-sizing: content-box;
            animation-name: mui-auto-fill-cancel;
            letter-spacing: inherit;
            animation-duration: 10ms;
            -webkit-tap-highlight-color: transparent; } }
    section.lazy-table-toolbar-controls {
        display: inline;
        margin-left: auto;
        margin-right: 0px; } }

div.chip-filter {
    border-radius: 8px;
    background-color: #CDEDFF;
    max-width: 304px;
    span.chip-icon-container {
        display: none; }
    div.chip-filter-container-label {
        display: flex;
        flex-flow: nowrap;
        span.chip-filter-label {
            display: inline-block;
            font-family: 'Lab Grotesque K';
            font-style: normal;
            font-weight: 400;
            height: 20px;
            font-size: 14px;
            line-height: 20px;
            color: #1c7edf;
            max-width: 210px;
            min-width: 0px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis; }
        span.chip-filter-label::after {
            content: ': \000a'; }
        span.chip-filter-value {
            display: inline-block;
            font-family: 'Lab Grotesque K';
            font-style: normal;
            font-weight: 400;
            height: 20px;
            font-size: 14px;
            line-height: 20px;
            color: #222;
            max-width: 210px;
            min-width: 0px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis; } } }

div.chip-filter:hover {
    border-radius: 8px;
    background-color: #CDEDFF;
    text-overflow: ellipsis;
    max-width: 304px;
    span.chip-filter-label {
        display: inline-block;
        font-family: 'Lab Grotesque K';
        font-style: normal;
        font-weight: 400;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #1c7edf;
        max-width: 210px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
    span.chip-filter-value {
        display: inline-block;
        font-family: 'Lab Grotesque K';
        font-style: normal;
        font-weight: 400;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #222;
        max-width: 210px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
    span.chip-icon-container {
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 6px;
        background-color: #CDEDFF;
        svg.chip-icon {
            color: #85BAEF;
            background-color: #CDEDFF;
            transform: scale(0.7); }
        svg.chip-icon:hover {
            color: #DD473B;
            background-color: #fff;
            border-radius: 6px;
            transform: scale(0.7); } } }

div.chip-filter-editor {
    z-index: 2;
    opacity: 1;
    background-color: #fff;
    padding: 16px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    span.w-label-edit {
        display: none; } }

div.filter-field-list {
    z-index: 2;
    opacity: 1;
    background-color: #fff;
    padding: 4px 0px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    cursor: pointer;
    width: 356px;
    height: 136px;
    ul.filter-field-ul {
        padding: 0px;
        li.filter-field {
            padding: 6px 8px;
            width: 336px;
            height: 32px;
            span.filter-field-text {
                width: 340px;
                height: 20px;
                line-height: 20px;
                font-family: 'Lab Grotesque K';
                font-style: normal;
                font-weight: 400;
                font-size: 14px; } }
        li.filter-field:hover {
            background-color: #1C7EDF;
            color: #fff; }
        li.filter-subheader {
            background-color: #fff;
            color: #858585;
            cursor: default;
            font-family: 'Lab Grotesque K';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            padding: 12px 8px 4px;
            line-height: 16px; } } }

div.lazy-table-toolbar-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px, 0px, 0px;
    gap: 16px;
    width: 415px;
    height: 36px;
    flex: none;
    order: 2;
    flex-grow: 0;
    span.lazy-table-toolbar-footer-text {
        width: auto;
        height: 16px;
        font-family: 'Lab Grotesque K';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: #ADADAD; }
    span.lazy-table-toolbar-footer-link-button {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;
        padding: 0px;
        gap: 5px;
        width: 168px;
        height: 20px;
        flex: none;
        order: 1;
        flex-grow: 0;
        cursor: pointer;
        span.lazy-table-toolbar-footer-link-button-icon {
            position: relative;
            width: auto;
            height: auto;
            background: #1874CF;
            color: #fff;
            border-radius: 50%;
            transform: scale(0.7); }
        span.lazy-table-toolbar-footer-link-button-button {
            position: relative;
            font-family: 'Lab Grotesque K';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #1C7EDF; } } }

div.checkbox_list_container_controls {
    margin-bottom: 16px;
    span.checkbox_list_container_buttons {
        span.checkbox_list_container_remove_all_button_container {
            cursor: pointer;
            div.checkbox_list_container_remove_all_button_icon_adjuster {
                display: inline-block;
                vertical-align: bottom;
                width: auto;
                height: auto;
                margin-left: 16px;
                background: #1874CF;
                color: #fff;
                border-radius: 50%;
                transform: scale(0.6); }
            div.checkbox_list_container_remove_all_button_adjuster {
                display: inline-block;
                vertical-align: bottom;
                width: auto;
                height: auto; } } } }

.checkbox_list_container_filter_field-ul {
    overflow-x: hidden !important; }

div.checkbox_list_container_filter_field_item {
    .checkbox_list_container_filter_field_item_checkbox {
        padding: 0px; }
    span.checkbox_list_container_filter_field_item_name {
        display: inline-block;
        font-family: 'Lab Grotesque K';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        width: 300px;
        color: #222;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle; }
    div.checkbox_list_container_filter_field_item_comment {
        display: inline-block;
        font-family: 'Lab Grotesque K';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #ADADAD; } }

span.lazy-table-view-toolbar-filter {
    button {
        svg {
            transform: scale(0.54, 0.58) translate(-8px, 0px); } } }
