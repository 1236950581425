@import "../../branding/Branding";

$fontsize: 10pt;

div.MuiPaper-elevation2 {
    box-shadow: none; }

span.material-icons.MuiIcon-root {
    font-size: 10pt; }

div.table-container {
    overflow-y: auto;
    height: 100%; }

table.MuiTable-root {
    white-space: nowrap;
    font-size: $fontsize;

    th.MuiTableCell-root {
        padding: 0px;
        span.MuiTableSortLabel-root {
            flex-direction: row; } }

    th.MuiTableCell-head {
        font-size: 8pt;
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 4px;
        padding-right: 4px;
        border-bottom: none;

        div.MuiTableCell-head-sortable {
            cursor: pointer; }
        div.MuiTableCell-head-sorting {
            cursor: progress; }
        div.MuiTableCell-head-unsortable {
            cursor: default; } }

    tr.MuiTableRow-root {
        font-size: $fontsize;
        &[data-owner='true'] {
            background-color: inherit;
            color: inherit; }
        &[data-owner='true']:hover {
            background-color: #eeeeee;
            color: $brandColor;
            cursor: pointer;
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-bottom-color: $brandColor; }
        &[data-owner='false'] {
            background-color: #DFDFDF;
            color: #707070; }
        &[data-owner='false']:hover {
            background-color: #eeeeee;
            color: $brandColor;
            cursor: pointer;
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-bottom-color: $brandColor; } }

    td.MuiTableCell-root {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 4px;
        padding-right: 4px;
        border-bottom: none;

        svg {
            padding: 0px;
            height: 10pt; } }

    tfoot.MuiTableFooter-root {
        height: 30px;
        justify-items: end;

        tr {
            height: 30px;

            &:hover {
                border-bottom-color: white;
                cursor: default; } } }

    td.MuiTableCell-body {
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: white; } }

.error {
    color: red; }

.MuiTableCell-head-asc {
    transform: rotate(180deg); }

.MuiTableCell-head-desc {
    transform: rotate(0deg); }
