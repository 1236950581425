div.loader-body {
    position: absolute;
    min-height: 50vh;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    opacity: 0.8;
    z-index: 100000; }
