@import "../../branding/Branding";

span.w-checkbox-view {
    font-size: $defaultFontSize; }

span.w-checkbox-edit {
    font-size: $defaultFontSize; }

.w-checkbox-center {
    div {
        position: relative; } }
