@import "../../branding/Branding";

$fontsize: 10pt;

div.lazy-table-container {
    overflow-y: hidden;
    height: 100%;
    z-index: 5;
    opacity: 1; }

table.MuiTable-root {
    white-space: nowrap;
    font-size: $fontsize;
    tr.MuiTableRow-root:hover {
        border-bottom-style: solid;
        border-width: 1px;
        border-block-color: #fff !important; }
    th.MuiTableCell-root {
        padding: 0px;
        cursor: default;
        span.MuiTableSortLabel-root {
            flex-direction: row; } }

    svg.MuiTableCell-head-checkbox-svg {
        padding: 0px;
        height: 24px;
        cursor: pointer; }

    th.MuiTableCell-head:hover {
        background-color: #f2f2f2; }

    th.MuiTableCell-paddingCheckbox {
        z-index: 1500;
        cursor: default; }
    th.MuiTableCell-paddingCheckbox:hover {
        background-color: #fff; }
    th.MuiTableCell-paddingNone {
        z-index: 1500;
        cursor: default; }
    th.MuiTableCell-paddingNone:hover {
        background-color: #fff; }

    td.MuiTableCell-root {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        padding-right: 8px;
        border: none;
        min-height: 20px;
        max-height: 58px; }

    tr.MuiTableRow-root {
        td.MuiTableCell-paddingNone:first-child {
            padding-left: 0px !important;
            padding-right: 8px !important;
            button.MuiIconButton-root {
                background-color: rgba(0, 0, 0, 0) !important;
                border-radius: 0 !important;
                span.MuiIconButton-label {
                    height: 24px !important;
                    width: 24px !important;
                    border-radius: 6px !important;
                    span.arrow-down {
                        color: #ADADAD; }
                    span.arrow-up {
                        color: #ADADAD; } } }
            button.MuiIconButton-root:hover {
                span.MuiIconButton-label:hover {
                    background-color: rgba(255, 255, 255, 0.9) !important;
                    span.arrow-down {
                        color: #222222; }
                    span.arrow-up {
                        color: #222222; } } } }
        td.MuiTableCell-paddingNone:last-child {
            padding-left: 24px !important;
            padding-right: 8px !important;
            button.MuiIconButton-root {
                height: 40px !important;
                width: 32px !important;
                padding: 8px 4px 8px 4px !important;
                border-radius: 0 !important;
                background-color: rgba(0, 0, 0, 0) !important;
                span.MuiIconButton-label {
                    height: 24px !important;
                    width: 24px !important;
                    border-radius: 6px !important;
                    visibility: hidden; }
                span.MuiIconButton-label:hover {
                    background-color: rgba(255, 255, 255, 0.9) !important; } } } }

    tr.MuiTableRow-root:hover {
        background-color: rgba(0, 0, 0, 0.04) !important;
        span.MuiIconButton-label {
            visibility: visible !important;
            span.row-action-end {
                color: #ADADAD; }
            span.row-action-end:hover {
                color: #DD473B; } } } }
