
div.multi-select-action-panel {
    position: absolute;
    width: auto;
    height: 40px;
    bottom: -110px;
    left: 0px;
    border: solid rgba(0, 0, 0, 0.865);
    border-radius: 4px;
    background: #000000;
    backdrop-filter: blur(16px);
    transition: bottom 300ms;

    div {
        -webkit-touch-callout: none /* iOS Safari */;
        -webkit-user-select: none /* Safari */;
        -khtml-user-select: none /* Konqueror HTML */;
        -moz-user-select: none /* Old versions of Firefox */;
        -ms-user-select: none /* Internet Explorer/Edge */;
        user-select: none /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */; }

    div.multi-select-token-panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        position: relative;
        filter: drop-shadow(4px 4px 16px rgba(0, 0, 0, 0.52));
        width: auto;
        height: 40px;
        gap: 1px;
        background: rgba(255, 255, 255, 0.22);
        font-family: 'Lab Grotesque K';
        font-weight: 400;
        font-style: normal;
        font-size: 16px;


        div.multi-select-action-token {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 8px 0px 8px;
            background: rgba(0, 0, 0, 0.865);
            backdrop-filter: blur(16px);
            height: 40px;
            line-height: 22px;
            color: #ffffff;
            white-space: nowrap;
            cursor: pointer; }

        div.multi-select-action-token:hover {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 8px 0px 8px;
            background: rgba(0, 0, 0, 0.45);
            backdrop-filter: blur(16px);
            height: 40px;
            line-height: 22px;
            color: #ffffff;
            white-space: nowrap;
            cursor: pointer; }

        div.multi-select-action-token-down {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 8px 0px 8px;
            background: rgba(0, 0, 0, 0.22);
            backdrop-filter: blur(16px);
            height: 40px;
            line-height: 22px;
            color: #ffffff;
            white-space: nowrap;
            cursor: pointer; }

        div.multi-select-action-token-disabled {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 8px 0px 8px;
            background: rgba(0, 0, 0, 0.865);
            backdrop-filter: blur(16px);
            height: 40px;
            line-height: 22px;
            color: rgba(255, 255, 255, 0.652);
            white-space: nowrap;
            cursor: pointer; } } }
