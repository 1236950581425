$fontSize: 1.2vw;
$minWidth: 1300px;

div#header {
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    >div>div>div>div>div {
        padding-right: 0px; } }

div#sections {
    position: fixed;
    left: 0px;
    width: 100%;
    background: #1e79be;
    height: 40px; }

div.top-menu {
    div {
        width: 100%;
        color: #fff;
        line-height: 15px;
        vertical-align: middle;
        margin: 0px;

        a {
            font-size: $fontSize;
            margin-left: $fontSize;
            margin-right: $fontSize;
            padding-left: $fontSize;
            padding-right: $fontSize; } } }

@media screen and (min-width: $minWidth) {
    div.top-menu {
        div a {
            font-size: 12pt;
            margin-left: 20px;
            margin-right: 20px;
            padding-left: 10px;
            padding-right: 10px; } } }

div.top-bar-end {
    font-size: $fontSize;
    div.organization {
        margin-right: 20px;
        >span {
            button {
                >div {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 300px;
                    >span {
                        >span {
                            text-align: right; } } } } } }
    svg {
        width: 1.2vw; }
    > div {
        align-items: center;
        button {
            font-size: $fontSize !important; } } }

@media screen and (min-width: $minWidth) {
    div.top-bar-end {
        font-size: 12pt;
        div.organization {
            >span {
                display: inline-block;
                max-width: 36vw;
                >span {
                    display: inline-block;
                    max-width: inherit;
                    width: 100%;
                    >span {
                        max-width: inherit !important;
                        button {
                            >div {
                                display: inline-block;
                                max-width: inherit;
                                >span {
                                    display: inline-block;
                                    width: 100%;
                                    >span {
                                        width: 100%;
                                        text-align: right; } } } } } } } }
        svg {
            width: 25px; }
        > div {
            align-items: center;
            button {
                font-size: 12pt !important; } } } }

