.user-editable-area-container {
    display: grid;
    grid-auto-rows: minmax(40px, auto);
    grid-column-gap: 10px;
    grid-row-gap: 1em;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    padding: 0 64px 0 32px; }

.roles-column {
    padding-top: 16px;
    display: flex;
    justify-content: space-between; }

.warning {
    padding: 8px;
    margin: 4px 0 4px 0;
    background-color: #eee;
    font-size: 0.8em;
    color: rgb(128, 128, 128); }

