@import "../../branding/Branding";

span.w-combobox-edit {
    width: 100%; }
div.items-container {
    width: 100%;
    display: flex; }
div.items-container-label {
    max-width: 200px;
    min-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .span {
        flexGrow: inherit;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; } }
div.items-container-edit {
    padding-left: 5px;
    opacity: 0.5;
    boxSizing: border-box;
    &:hover {
        opacity: 1.0; } }
.add-button-item {
    max-width: 506px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }

