div.plan-panel {
    left: 90px;
    top: 7px;
    right: 0;
    bottom: 0;
    overflow-y: hidden;
    position: fixed;
    margin-right: 3px;
    margin-bottom: 55px;
    height: calc(100% - 16px);
    border: solid 0px black;

    td.MuiTableCell-root {
        >span.w-text-view {
            display: inline-block;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap; }
        >div.w-text-view-overflow {
            >span.w-text-view {
                display: inline-block;
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap; } } }

    table.MuiTable-root {
        th.MuiTableCell-head {
            border: solid 1px #cccccc; } }

    td.MuiTableCell-body {
        border: solid 1px #cccccc; } }

